<template>
  <div v-frag>
    <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
      <v-btn
        :loading='loadingAddNew'
        active-class='primary-light'
        class='primary--text text-capitalize mr-1'
        color='primary'
        outlined
        @click='addCheckList'
      >
        <v-icon color='primary'>mdi-plus</v-icon>
        <b class='primary--text text-capitalize'>Add New</b>
      </v-btn>
    </v-col>
    <v-progress-linear v-if='loadingTable'
                       color='primary'
                       indeterminate
    >
    </v-progress-linear>
    <v-col
      v-for='(item, index) in items'
      v-else
      :key='index'
      cols='12'
      lg='12'
      md='12'
      sm='12'
      xl='12'
      xs='12'

    >
      <div>
        <v-card>
          <v-card-title>
            <v-row>
              <v-col cols='4' lg='4' md='4' sm='4' xl='4' xs='4'> Hierarchy</v-col>
              <v-col cols='4' lg='4' md='4' sm='4' xl='4' xs='4'> Date</v-col>
              <v-col cols='4' lg='4' md='4' sm='4' xl='4' xs='4'> Tasks</v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <div
                v-for='(date, indexDate) in item.date'
                :key='indexDate'
                v-frag
              >
                <v-col cols='4' lg='4' md='4' sm='4' xl='4' xs='4'>
                  <v-autocomplete
                    v-if='indexDate === 0'
                    v-model='item.hierarchy.role_id'
                    :items='itemsHierarchy'
                    class='mt-3'
                    dense
                    outlined
                    placeholder='Hierarchy'
                    required
                    @change='updateHierarchy(item.hierarchy)'
                  ></v-autocomplete>
                </v-col>
                <v-col cols='4' lg='4' md='4' sm='4' xl='4' xs='4'>
                  <v-menu
                    ref='menu3'
                    v-model='date.modal'
                    :close-on-content-click='false'
                    max-width='290px'
                    min-width='auto'
                    offset-y
                    transition='scale-transition'
                  >
                    <template v-slot:activator='{ on, attrs }'>
                      <v-text-field
                        v-model='date.dateView'
                        autocomplete='off'
                        class='mt-3'
                        dense
                        outlined
                        required
                        v-bind='attrs'
                        v-on='on'
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model='date.date'
                      no-title
                      @change='update(date)'
                      @input='date.modal = false'
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class='mt-3' cols='4' lg='4' md='4' sm='4' xl='4' xs='4'>
                  <div v-if='item.date.length > 0' v-frag>
                    {{ date.task }}
                    {{ date.task > 1 ? 'Tasks' : 'Task' }}
                  </div>

                  <v-btn
                    :loading='loadingManageTasks[date.id]'
                    active-class='primary-light'
                    class='primary--text text-capitalize mr-1'
                    color='primary'
                    outlined
                    @click='viewTask(date.id)'
                  >
                    <b class='primary--text text-capitalize'>Manage tasks</b>
                  </v-btn>
                  <v-btn
                    :loading='date.loadingDelete'
                    active-class='primary-light'
                    class='red--text text-capitalize mr-1'
                    color='red'
                    outlined
                    style='float: right'
                    @click='deleteDialog(date, 1)'
                  >
                    <b class='red--text text-h3'>-</b>
                  </v-btn>
                </v-col>
              </div>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols='4' lg='4' md='4' sm='4' xl='4' xs='4'></v-col>
              <v-col cols='4' lg='4' md='4' sm='4' xl='4' xs='4'>
                <v-btn
                  :loading='loadingAddDate[item.id]'
                  active-class='primary-light'
                  block
                  class='primary--text text-capitalize mr-1'
                  color='primary'
                  outlined
                  @click='addNewRol(item.id)'
                >
                  <v-icon color='primary'>mdi-plus</v-icon>
                  <b class='primary--text text-capitalize'>Add Date</b>
                </v-btn>
              </v-col>
              <v-col cols='4' lg='4' md='4' sm='4' xl='4' xs='4'></v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </div>
    </v-col>
    <v-dialog
      v-model='dialogNotes'
      max-width='1000px'
      persistent
      scrollable
      transition='dialog-bottom-transition'
    >
      <v-card>
        <v-card-title class='justify-space-between'>
          <span class='headline'>Manage tasks</span>
          <v-btn color='primary'
                 icon
                 large
                 @click='cancel'
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class='py-2 px-4'>
          <v-container>
            <v-form ref='form' lazy-validation>
              <v-row>
                <div v-for='(note, index) in notes' :key='index' v-frag>
                  <v-col cols='10' lg='10' md='10' sm='10' xl='10' xs='10'>
                    <v-textarea
                      v-model='note.description'
                      :rules='descriptionRules'
                      auto-grow
                      counter
                      label='Description'
                      outlined
                    ></v-textarea>
                  </v-col>
                  <v-col cols='2' lg='2' md='2' sm='2' xl='2' xs='2'>
                    <v-btn
                      active-class='primary-light'
                      class='red--text text-capitalize mr-1'
                      color='red'
                      outlined
                      style='float: right'
                      @click='deleteDialog(note, 2)'
                    >
                      <b class='red--text text-h3'>-</b>
                    </v-btn>
                  </v-col>
                </div>
                <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
                  <v-btn
                    :loading='loadingAddNewTasks'
                    active-class='primary-light'
                    block
                    class='primary--text text-capitalize mr-1'
                    color='primary'
                    outlined
                    @click='addNewTask'
                  >
                    <v-icon color='primary'>mdi-plus</v-icon>
                    <b class='primary--text text-capitalize'>Add New Task</b>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class='d-flex direction-row justify-end'>
          <v-btn
            :loading='loadingSave'
            class='px-14 font-weight-bold white--text elevation-0'
            color='primary'
            large
            @click='saveNotes'
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model='dialogDelete' max-width='500px' persistent>
      <v-card class='d-flex flex-column align-start pa-2'>
        <v-card-title class='text-center mb-5'>
          <v-icon class='mr-4 white--text' color='warning' large left
          >mdi-alert
          </v-icon
          >
          Delete task
        </v-card-title>
        <v-card-subtitle>
          <b>Are you sure you want to delete this record?:</b>
        </v-card-subtitle>
        <v-card-actions class='align-self-center'>
          <v-spacer></v-spacer>
          <v-btn
            class='px-14 mx-4 font-weight-bold text-capitalize'
            color='primary'
            large
            outlined
            @click='dialogDelete = false'
          >
            Cancel
          </v-btn
          >
          <v-btn
            :loading='loadingDelete'
            class='px-14 mx-4 font-weight-bold text-capitalize white--text'
            color='warning'
            large
            @click='deleteHierarchy'
          >
            Delete
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div style='display: none'>{{ alert.show }}</div>
  </div>
</template>

<script>
import task from '../../../services/task'
import communication from '../../../services/communication'

export default {
  mounted() {
    this.initialLoad()
  },
  computed: {
    alert() {
      return this.$store.getters.alert
    }
  },
  data: () => ({
    descriptionRules: [(v) => !!v || 'Description is required'],
    typingTimer: null,
    selected: [],
    items: [],
    loadingDelete: false,
    loadingAddNew: false,
    loadingAddNewTasks: false,
    loadingTable: true,
    loadingManageTasks: {},
    loadingAddDate: {},
    loadingSave: false,
    itemsHierarchy: [
      {text: 'aaa', value: 1},
      {text: 'bbb', value: 2}
    ],
    dialogNotes: false,
    hierarchyId: null,
    notes: [],
    dialogDelete: false,
    itemDelete: null,
    deleteType: null
  }),
  methods: {
    async initialLoad() {
      try {
        await this.getHierarchy()
        await this.getData()
      } catch (e) {
        //empty
      }
    },
    async getData() {
      try {
        this.items = await task.index(this.$route.params.id)
        this.loadingTable = false
      } catch (e) {
        this.loadingTable = false
      }
    },
    async getHierarchy() {
      try {
        this.itemsHierarchy = await communication.getEmployeeRoles()
      } catch (e) {
        // empty
      }
    },
    async addCheckList() {
      this.loadingAddNew = true
      try {
        const res = await task.store(this.$route.params.id)
        this.$store.dispatch('alert', {
          show: false,
          color: 'green',
          title: 'Success message!',
          message: res.message,
          response: true
        })
        await this.getData()
        this.loadingAddNew = false
      } catch (e) {
        this.loadingAddNew = false
      }
    },
    async update(item) {
      try {
        const res = await task.updated(item.id, item.date)
        this.$store.dispatch('alert', {
          show: false,
          color: 'green',
          title: 'Success message!',
          message: res.message,
          response: true
        })
        await this.getData()
      } catch (e) {
        //empty
      }
    },
    async updateHierarchy(item) {
      try {
        const res = await task.updateHierarchy(item.id, item.role_id)
        this.$store.dispatch('alert', {
          show: false,
          color: 'green',
          title: 'Success message!',
          message: res.message,
          response: true
        })
        await this.getData()
      } catch (e) {
        //empty
      }
    },
    async viewTask(id) {
      let helper = Object.assign({}, this.loadingManageTasks)
      helper[id] = true
      this.loadingManageTasks = helper
      this.hierarchyId = id
      await this.getTask()
    },
    async getTask() {
      try {
        const res = await task.viewNotes(this.hierarchyId)
        if (this.notes.length) {
          for (let i = 0; i < this.notes.length; i++) {
            if (this.notes[i].description) res[i].description = this.notes[i].description
          }
        }
        this.notes = res
        this.dialogNotes = true
        this.loadingManageTasks = false
      } catch (e) {
        this.loadingManageTasks = false
      }
    },
    async addNewTask() {
      this.loadingAddNewTasks = true
      try {
        const res = await task.newTask(this.hierarchyId)
        this.$store.dispatch('alert', {
          show: false,
          color: 'green',
          title: 'Success message!',
          message: res.message,
          response: true
        })
        await this.getTask()
        this.loadingAddNewTasks = false
      } catch (e) {
        this.loadingAddNewTasks = false
      }
    },
    async saveNotes() {
      if (this.$refs.form.validate()) {
        this.loadingSave = true
        try {
          for (const note of this.notes) {
            await task.updateNote(note.id, note.description)
          }
        } catch (e) {
          this.loadingSave = false
        }
        this.loadingSave = false
        this.cancel()
      }
    },
    cancel() {
      if (this.$refs.form.validate()) {
        this.dialogNotes = false
        this.hierarchyId = null
        this.notes = []
        this.getData()
      }
    },
    closeDialogDelete() {
      this.dialogDelete = false
      this.deleteType = null
      this.itemDelete = null
    },
    deleteDialog(data, type) {
      this.itemDelete = data.id
      this.deleteType = type
      this.dialogDelete = true
    },
    async deleteHierarchy() {
      this.loadingDelete = true
      try {
        let res
        if (this.deleteType === 1) {
          res = await task.deleteHierarchy(this.itemDelete)
        } else {
          res = await task.deleteNote(this.itemDelete)
          this.notes.forEach((el, i) => {
            if (el.id === this.itemDelete) this.notes.splice(i, 1)
          })
          await this.getTask()
        }
        this.$store.dispatch('alert', {
          show: false,
          color: 'green',
          title: 'Success message!',
          message: res.message,
          response: true
        })
        await this.getData()
        await this.closeDialogDelete()
        this.loadingDelete = false
      } catch (e) {
        this.loadingDelete = false
      }
    },
    async addNewRol(id) {
      let helper = Object.assign({}, this.loadingAddDate)
      helper[id] = true
      this.loadingAddDate = helper
      try {
        const res = await task.storeHieranchy(id)
        this.$store.dispatch('alert', {
          show: false,
          color: 'green',
          title: 'Success message!',
          message: res.message,
          response: true
        })
        await this.getData()
        this.loadingAddDate = false
      } catch (e) {
        this.loadingAddDate = false
      }
    }
  }
}
</script>
