import {HTTP} from '../../../http-common'
import helper from '../index'

export default {
  index: async(id) => {
    try {
      const response = await HTTP.get('task', {
        headers: helper.setHeaders(),
        params: {
          id: id
        }
      })
      return response.data.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  store: async(id) => {
    try {
      const response = await HTTP.post(
        'task',
        {
          id: id
        },
        {
          headers: helper.setHeaders()
        }
      )
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  updated: async(id, date) => {
    try {
      const response = await HTTP.put(
        `task/${id}`,
        {
          date: date
        },
        {
          headers: helper.setHeaders()
        }
      )
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  
  storeHieranchy: async(id) => {
    try {
      const response = await HTTP.post(
        `task-date`,
        {
          id: id
        },
        {
          headers: helper.setHeaders()
        }
      )
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  updateHierarchy: async(id, role_id) => {
    try {
      const response = await HTTP.post(
        `task-hieranchy/${id}`,
        {
          role_id: role_id
        },
        {
          headers: helper.setHeaders()
        }
      )
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  deleteHierarchy: async(id) => {
    try {
      const response = await HTTP.post(
        `task-date-delete/${id}`,
        {},
        {
          headers: helper.setHeaders()
        }
      )
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  
  viewNotes: async(id) => {
    try {
      const response = await HTTP.get('task-notes/' + id, {
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  newTask: async(id) => {
    try {
      const response = await HTTP.post(
        `task-new-note`,
        {
          id: id
        },
        {
          headers: helper.setHeaders()
        }
      )
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  
  updateNote: async(id, description) => {
    try {
      const response = await HTTP.post(
        `task-update-note/${id}`,
        {
          description: description
        },
        {
          headers: helper.setHeaders()
        }
      )
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  getDetailCard: async(id) => {
    try {
      const response = await HTTP.get('task-detail/' + id, {
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  
  indexTask: async(id) => {
    try {
      const response = await HTTP.get('task-election/' + id, {
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  
  completedTask: async(id, completed) => {
    try {
      const response = await HTTP.post(
        `task-completed-note/${id}`,
        {
          completed: completed
        },
        {
          headers: helper.setHeaders()
        }
      )
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  
  deleteNote: async(id) => {
    try {
      const response = await HTTP.post(
        `task-delete-note/${id}`,
        {},
        {
          headers: helper.setHeaders()
        }
      )
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  }
}
