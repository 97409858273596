import {HTTP} from '../../../http-common'
import helper from '../index'

export default {
  counties: async() => {
    try {
      const response = await HTTP.get('counties-select', {
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },

  index: async(data) => {
    try {
      const response = await HTTP.get('elections', {
        params: data,
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      await helper.errorHandler(e.response)
      return e.response
    }
  },

  show: async(id) => {
    try {
      const response = await HTTP.get('elections/' + id, {
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },

  store: async(data) => {
    try {
      const response = await HTTP.post(
        'elections',
        data,
        {
          headers: helper.setHeaders()
        }
      )
      return response.data.data
    } catch (e) {
      await helper.errorHandler(e.response)
      return e.response
    }
  },
  updated: async(id, data) => {
    try {
      const response = await HTTP.put(
        'elections/' + id,
        data,
        {
          headers: helper.setHeaders()
        }
      )
      return response.data
    } catch (e) {
      await helper.errorHandler(e.response)
      return e.response
    }
  },

  delete: async(data) => {
    try {
      const response = await HTTP.post(
        `elections-delete-items`,
        {
          data: data
        },
        {
          headers: helper.setHeaders()
        }
      )
      return response.data
    } catch (e) {
      await helper.errorHandler(e.response)
      return e.response
    }
  },

  getTimeline: async(id) => {
    try {
      const response = await HTTP.get('elections-timeline/' + id, {
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      await helper.errorHandler(e.response)
      return e.response
    }
  },

  storeTimeLine: async(idCampaign) => {
    try {
      const response = await HTTP.post(
        'elections-add-timeline',
        {
          election_id: idCampaign
        },
        {
          headers: helper.setHeaders()
        }
      )
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },

  updatedTimeLine: async({id, name, date, time}) => {
    try {
      const response = await HTTP.post(
        'elections-updated-timeline',
        {
          id: id,
          description: name,
          date: date,
          time: time
        },
        {
          headers: helper.setHeaders()
        }
      )
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },

  deleteTimeLine: async(timelines) => {
    try {
      const response = await HTTP.post(
        'elections-deleted-timeline',
        {
          timelines: timelines
        },
        {
          headers: helper.setHeaders()
        }
      )
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },

  deleteTimelineCommunication: async(communicationId, timeLineId) => {
    try {
      const response = await HTTP.post(
        `elections-deleted-timeline-communication`,
        {
          communication_id: communicationId,
          timeline_id: timeLineId
        },
        {
          headers: helper.setHeaders()
        }
      )
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },

  getElectionCurrent: async() => {
    try {
      const response = await HTTP.get('elections-current', {
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  }
}
