<template>
  <div v-frag>
    <!-- <v-dialog
      v-model="crud.dialog"
      transition="dialog-bottom-transition"
      scrollable
      persistent
      max-width="1000px"
    > -->
    <v-card elevation='0'>
      <v-card-title>
        <span class='headline'>{{ idCampaign ? 'Edit Campaign' : 'New Campaign' }}</span>
      </v-card-title>
      <v-card-text class='py-2 px-4'>
        <v-container>
          <v-form ref='form' lazy-validation>
            <v-row>
              <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
                <p class='font-weight-bold'>Campaign name*</p>
                <v-text-field
                  v-model='inputs.description'
                  :rules='nameRules'
                  dense
                  outlined
                  placeholder='new Campaign'
                  required
                  type='text'
                ></v-text-field>
              </v-col>

              <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                <p class='font-weight-bold'>Start Date*</p>
                <v-menu
                  ref='menu1'
                  v-model='menu1'
                  :close-on-content-click='false'
                  max-width='290px'
                  min-width='auto'
                  offset-y
                  transition='scale-transition'
                >
                  <template #activator='{ on, attrs }'>
                    <v-text-field
                      v-model='inputs.start'
                      :rules='startEndDate'
                      dense
                      outlined
                      required
                      v-bind='attrs'
                      v-on='on'
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model='inputs.start'
                    no-title
                    @input='menu1 = false'
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                <p class='font-weight-bold'>End date*</p>
                <v-menu
                  ref='menu2'
                  v-model='menu2'
                  :close-on-content-click='false'
                  max-width='290px'
                  min-width='auto'
                  offset-y
                  transition='scale-transition'
                >
                  <template #activator='{ on, attrs }'>
                    <v-text-field
                      v-model='inputs.end'
                      :rules='startEndDate'
                      autocomplete='off'
                      dense
                      outlined
                      required
                      v-bind='attrs'
                      v-on='on'
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model='inputs.end'
                    no-title
                    @input='menu2 = false'
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
                <v-btn
                  active-class='green-ligth'
                  class='green--text text-capitalize ml-1'
                  color='green'
                  outlined
                  style='float: right'
                  @click='importVoting'
                >
                  <v-icon color='green'>mdi-import</v-icon>
                  <b class='green--text text-capitalize'>Import voting</b>
                </v-btn>
              </v-col>

              <v-col cols='12' lg='4' md='4' sm='12' xl='4' xs='12'>
                <p class='font-weight-bold'>Early Voting Start Date</p>
                <v-menu
                  ref='menu5'
                  v-model='menu5'
                  :close-on-content-click='false'
                  max-width='290px'
                  min-width='auto'
                  offset-y
                  transition='scale-transition'
                >
                  <template #activator='{ on, attrs }'>
                    <v-text-field
                      v-model='inputs.earlyVoteStartDate'
                      dense
                      outlined
                      v-bind='attrs'
                      v-on='on'
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model='inputs.earlyVoteStartDate'
                    no-title
                    @input='menu5 = false'
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols='12' lg='4' md='4' sm='12' xl='4' xs='12'>
                <p class='font-weight-bold'>End of First Week of Early Voting</p>
                <v-menu
                  ref='menuFirstWeek'
                  v-model='menuFirstWeek'
                  :close-on-content-click='false'
                  max-width='290px'
                  min-width='auto'
                  offset-y
                  transition='scale-transition'
                >
                  <template #activator='{ on, attrs }'>
                    <v-text-field
                      v-model='inputs.firstWeekDate'
                      dense
                      :rules='firstWeekRules'
                      outlined
                      v-bind='attrs'
                      v-on='on'
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model='inputs.firstWeekDate'
                    no-title
                    @input='menuFirstWeek = false'
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols='12' lg='4' md='4' sm='12' xl='4' xs='12'>
                <p class='font-weight-bold'>Early Voting End Date</p>
                <v-menu
                  ref='menu6'
                  v-model='menu6'
                  :close-on-content-click='false'
                  max-width='290px'
                  min-width='auto'
                  offset-y
                  transition='scale-transition'
                >
                  <template #activator='{ on, attrs }'>
                    <v-text-field
                      v-model='inputs.earlyVoteEndDate'
                      dense
                      outlined
                      v-bind='attrs'
                      v-on='on'
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model='inputs.earlyVoteEndDate'
                    no-title
                    @input='menu6 = false'
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                <p class='font-weight-bold'>Election Day</p>
                <v-menu
                  ref='menu7'
                  v-model='menu7'
                  :close-on-content-click='false'
                  max-width='290px'
                  min-width='auto'
                  offset-y
                  transition='scale-transition'
                >
                  <template #activator='{ on, attrs }'>
                    <v-text-field
                      v-model='inputs.voteDate'
                      dense
                      outlined
                      v-bind='attrs'
                      v-on='on'
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model='inputs.voteDate'
                    no-title
                    @input='menu7 = false'
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                <p class='font-weight-bold'>Freeze Final Election Data</p>
                <v-menu
                  ref='menu8'
                  v-model='menu8'
                  :close-on-content-click='false'
                  max-width='290px'
                  min-width='auto'
                  offset-y
                  transition='scale-transition'
                >
                  <template #activator='{ on, attrs }'>
                    <v-text-field
                      v-model='inputs.freezeDate'
                      dense
                      outlined
                      v-bind='attrs'
                      v-on='on'
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model='inputs.freezeDate'
                    no-title
                    @input='menu8 = false'
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols='12' lg='3' md='3' sm='6' xl='3' xs='6'>
                <p class='font-weight-bold'>Runoff Election</p>
                <v-checkbox
                  v-model='inputs.runoffElection'
                  class='d-inline-block ma-0'
                ></v-checkbox>
              </v-col>
              <v-col cols='12' lg='3' md='3' sm='6' xl='3' xs='6'>
                <p class='font-weight-bold'>Show Candidate Party Affiliation</p>
                <v-checkbox
                  v-model='inputs.isParty'
                  class='d-inline-block ma-0'
                ></v-checkbox>
              </v-col>
              <!-- <v-col cols="12" sm="12" xs="12" md="12" lg="12" xl="12">
                <v-switch v-model="inputs.current" label="Current"></v-switch>
              </v-col> -->

              <div v-if='idCampaign' v-frag>
                <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
                  <v-tabs v-model='tab'>
                    <v-tab>Communication key dates</v-tab>
                    <v-tab>Campaign checklist</v-tab>
                  </v-tabs>
                </v-col>

                <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
                  <v-tabs-items v-model='tab'>
                    <v-tab-item>
                      <v-row>
                        <v-col
                          cols='12'
                          lg='12'
                          md='12'
                          sm='12'
                          xl='12'
                          xs='12'
                        >
                          <v-btn
                            active-class='primary-ligth'
                            class='primary--text text-capitalize mr-1'
                            color='primary'
                            outlined
                            @click='addTimeLine'
                          >
                            <v-icon color='primary'>mdi-plus</v-icon>
                            <b class='primary--text text-capitalize'>Add New</b>
                          </v-btn>
                          <div v-if='selected.length > 0' v-frag>
                            <v-btn
                              active-class='red-ligth'
                              class='red--text text-capitalize'
                              color='red'
                              outlined
                              @click='deleteTimeLine'
                            >
                              <v-icon color='red'>mdi-close-thick</v-icon>
                              <b class='red--text text-capitalize'>Delete</b>
                            </v-btn>
                          </div>
                        </v-col>
                        <v-col
                          cols='12'
                          lg='12'
                          md='12'
                          sm='12'
                          xl='12'
                          xs='12'
                        >
                          <v-data-table
                            v-model='selected'
                            :headers='headers'
                            :items='timeLine'
                            :loading='loading'
                            :single-select='false'
                            checkboxColor='primary'
                            class='elevation-0 data-table-border-radius'
                            disable-pagination
                            elevation='0'
                            hide-default-footer
                            item-key='id'
                            show-select
                            style='border: 2px solid #ecf1f9'
                          >
                            <template #[`item.date`]='{ item }'>
                              <v-menu
                                ref='menu3'
                                v-model='item.modal'
                                :close-on-content-click='false'
                                max-width='290px'
                                min-width='auto'
                                offset-y
                                transition='scale-transition'
                              >
                                <template #activator='{ on, attrs }'>
                                  <v-text-field
                                    v-model='item.date'
                                    :error='item.error'
                                    :messages="item.error ? 'The date entered does not match' : ''"
                                    autocomplete='off'
                                    class='mt-3'
                                    dense
                                    outlined
                                    required
                                    v-bind='attrs'
                                    v-on='on'
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model='item.date'
                                  no-title
                                  @change='updatedTimeLine(item)'
                                  @input='item.modal = false'
                                ></v-date-picker>
                              </v-menu>
                            </template>

                            <template #[`item.time`]='{ item }'>
                              <vue-timepicker
                                v-model='item.timeView'
                                :minute-interval='15'
                                append-to-body
                                auto-scroll
                                close-on-complete
                                format='h:mm A'
                                style='margin-top: -10px'
                                @close='updatedTimeLine(item, true, $event)'
                              ></vue-timepicker>
                            </template>

                            <template #[`item.name`]='{ item }'>
                              <v-text-field
                                v-model='item.name'
                                class='mt-3'
                                dense
                                outlined
                                required
                                type='text'
                                @change='updatedTimeLine(item)'
                              ></v-text-field>
                            </template>

                            <template #[`item.communication`]='{ item, index }'>
                              <div class='text-center'>
                                <div
                                  v-for='(itemCommunication, indexCommunication) in item.communication'
                                  :key='indexCommunication'
                                  v-frag
                                >
                                  <v-chip
                                    v-if='itemCommunication'
                                    class='ma-2'
                                    close
                                    color='primary'
                                    text-color='white'
                                    @click='openDialogCommunication(index, itemCommunication)'
                                    @click:close='removeCommunication(itemCommunication.id, item.id)'
                                  >
                                    {{ itemCommunication.name }}
                                  </v-chip>
                                </div>
                                <v-btn
                                  :disabled='!item.communicationAdd'
                                  color='primary'
                                  elevation='0'
                                  small
                                  @click='openDialogCommunication(index, null)'
                                >
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                              </div>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-tab-item>

                    <v-tab-item>
                      <v-row>
                        <v-col cols='12'>
                          <table-check-list/>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs-items>
                </v-col>
              </div>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class='d-flex direction-row justify-end pt-10 pb-10'>
        <v-btn
          :disabled='loading'
          class='px-14 mx-4 font-weight-bold primary--text elevation-0'
          color='white'
          large
          @click='closeDialog'
        >
          Cancel
        </v-btn>
        <v-btn
          :disabled='loading'
          :loading='loading'
          class='px-14 mx-4 font-weight-bold white--text'
          color='primary'
          large
          @click='save'
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
    <dialog-communication/>
    <!-- </v-dialog> -->
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import campaign from '../../../services/campaign'
import DialogCommunication from '../../communication/components/DialogCommunication'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import TableCheckList from './TableCheckList.vue'

export default {
  components: {
    DialogCommunication,
    VueTimepicker,
    TableCheckList
  },
  data() {
    return {
      tab: 0,
      id: null,
      inputs: {
        description: '',
        start: '',
        end: '',
        county: 1,
        current: false,
        earlyVoteStartDate: '',
        earlyVoteEndDate: '',
        firstWeekDate: '',
        voteDate: '',
        freezeDate: '',
        runoffElection: false,
        isParty: false
      },

      loading: false,
      nameRules: [(v) => !!v || 'Name is required'],

      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      menu7: false,
      menu8: false,
      menuFirstWeek: false,
      selected: [],
      headers: [
        {
          text: 'Date',
          align: 'start',
          value: 'date',
          divider: true,
          width: '25%'
        },
        {
          text: 'Time',
          align: 'start',
          value: 'time',
          divider: true,
          width: '25%'
        },
        {
          text: 'Name',
          align: 'start',
          value: 'name',
          divider: true,
          width: '25%'
        },
        {
          text: 'Communication',
          align: 'start',
          value: 'communication',
          divider: true,
          width: '25%'
        }
      ],
      timeLine: []
    }
  },
  computed: {
    idCampaign() {
      if (this.$route.params.id) {
        return this.$route.params.id
      }
      if (this.id) {
        return this.id
      }
      return null
    },
    startEndDate() {
      return [
        this.inputs.start !== '' || 'Start Date is required',
        this.inputs.end !== '' || 'End Date is required',
        this.inputs.start <= this.inputs.end || 'The left and start must be less than the end date'
      ]
    },
    firstWeekRules() {
      return [
        this.inputs.firstWeekDate !== '' || 'End of First Week of Early Voting is required',
        this.inputs.firstWeekDate <= this.inputs.earlyVoteEndDate || 'End of First Week of Early Voting must be less than the Early Vote End Date',
        this.inputs.firstWeekDate >= this.inputs.earlyVoteStartDate || 'End of First Week of Early Voting must be bigger than the Early Vote Start Date'
      ]
    }
  },
  async created() {
    await this.initialLoad()
  },
  methods: {
    ...mapActions({
      updateElection: 'electionStore/updateElection',
      alert: 'alert',
      crud: 'crud'
    }),
    async initialLoad() {
      try {
        this.loading = true
        if (this.idCampaign)
          await Promise.allSettled([this.getData(), this.getTimeline()])
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    async getData() {
      try {
        const res = await campaign.show(this.idCampaign)
        this.inputs.description = res.description
        this.inputs.start = res.start
        this.inputs.end = res.end
        this.inputs.earlyVoteStartDate = res.early_vote_start_date
        this.inputs.earlyVoteEndDate = res.early_vote_end_date
        this.inputs.voteDate = res.vote_date
        this.inputs.county = res.countie_id
        this.inputs.current = res.current
        this.inputs.freezeDate = res.freeze_date
        this.inputs.runoffElection = res.runoffElection
        this.inputs.isParty = res.is_party
        this.inputs.firstWeekDate = res.first_week_end_date
      } catch (e) {
        console.log(e)
      }
    },
    async save() {
      this.idCampaign ? await this.updated() : await this.store()
    },
    async store() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          const data = {
            description: this.inputs.description,
            start: this.inputs.start,
            end: this.inputs.end,
            early_vote_start_date: this.inputs.earlyVoteStartDate,
            early_vote_end_date: this.inputs.earlyVoteEndDate,
            vote_date: this.inputs.voteDate,
            state: this.inputs.state,
            county: this.inputs.county,
            current: this.inputs.current,
            freeze_date: this.inputs.freezeDate,
            runoffElection: this.inputs.runoffElection,
            is_party: this.inputs.isParty,
            first_week_end_date: this.inputs.firstWeekDate
          }
          const res = await campaign.store(data)
          setTimeout(() => {
            this.$router.replace({
              name: 'DialogCampaignUpdate',
              params: {
                id: res
              }
            })
          }, 1000)
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      }
    },
    async updated() {
      const data = {
        description: this.inputs.description,
        start: this.inputs.start,
        end: this.inputs.end,
        early_vote_start_date: this.inputs.earlyVoteStartDate,
        early_vote_end_date: this.inputs.earlyVoteEndDate,
        vote_date: this.inputs.voteDate,
        state: this.inputs.state,
        county: this.inputs.county,
        current: this.inputs.current,
        freeze_date: this.inputs.freezeDate,
        runoffElection: this.inputs.runoffElection,
        is_party: this.inputs.isParty,
        first_week_end_date: this.inputs.firstWeekDate,
        id: this.idCampaign
      }
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          const res = await this.updateElection(data)
          this.closeDialog()
          await this.alert({
            show: true,
            color: 'green',
            title: 'Success message!',
            message: res.message,
            response: true
          })
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      }
    },
    closeDialog() {
      this.$refs.form.reset()
      this.timeLine = []
      this.id = null
      this.$router.replace({ name: 'Campaign' })
      // this.$store.dispatch("crudTwo", {
      //   dialog: false,
      //   title: "",
      //   id: null,
      //   data: {},
      // });
    },
    async getTimeline() {
      try {
        this.loading = true
        this.timeLine = await campaign.getTimeline(this.idCampaign)
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    async addTimeLine() {
      try {
        this.loading = true
        await campaign.storeTimeLine(this.idCampaign)
        await this.getTimeline()
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    closeDialogTime(item) {
      item.modalTime = false
    },
    async updatedTimeLine(item, time = null, event = null) {
      try {
        this.loading = true
        let aux = item
        if (time) {
          const data = event.data
          if (data.H === '' || data.mm === '' || data.A === '') {
            return
          }
          aux.time = data.kk + ':' + data.mm
          item = aux
        }
        item.error = false
        await campaign.updatedTimeLine(aux)
        await this.getTimeline()
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },

    async deleteTimeLine() {
      try {
        this.loading = true
        let aux = []
        for (let index in this.selected) {
          aux.push(this.selected[index].id)
        }
        await campaign.deleteTimeLine(aux)
        await this.getTimeline()
        this.selected = []
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },

    async removeCommunication(communicationId, timeLineId) {
      try {
        this.loading = true
        await campaign.deleteTimelineCommunication(communicationId, timeLineId)
        await this.getTimeline()
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    openDialogCommunication(indexTimeline, communication) {
      const text = communication == null ? 'New Communication' : 'Edit Communication'
      const aux = communication == null ? null : communication.id
      this.crud({
        dialog: true,
        title: text,
        id: aux,
        data: {
          type_send: 1,
          campaign: true,
          viewVotes: true,
          campaign_id: this.timeLine[indexTimeline].id,
          date: this.timeLine[indexTimeline].date,
          time: this.timeLine[indexTimeline].time
        }
      })
    },

    importVoting() {
      this.$router.push({
        name: 'ImportVoting',
        params: { campaing: this.idCampaign }
      })
    }
  }
}
</script>
